import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/HomePage')
  },
  {
    path: '/privacy',
    name: '光谱计划隐私协议',
    meta: { title: '光谱计划隐私协议' },
    component: () => import('@/views/PrivacyAgreement')
  },
  {
    path: '/agreement',
    name: '光谱计划用户注册协议',
    meta: { title: '光谱计划用户注册协议' },
    component: () => import('@/views/RegistrationProtocol')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
