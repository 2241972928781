<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  mounted () {}
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  width: 100px;
  height: 100%;
  min-width: 100%;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
</style>
